<template>
  <div class="pbf">
     
     <BrandTabDiaries
      :inurl="'/brands/' + props.id + '/diaries'"
      :category="'seed'"
      :brand-id="props.id"
      :intags="tags"
      />

  </div>
</template>

<script setup>
 
const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  }
})

// const link = ref(route.params['id']);
const tags = ref($tagsUtil.tagsBrandSeedDiaries)

</script>

<style scoped>
 

</style>
